(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name menu.controller:MenuCtrl
   *
   * @description
   *
   */
  angular
    .module('menu')
    .controller('MenuCtrl', MenuCtrl);

  function MenuCtrl($scope, $rootScope) {
      $rootScope.pageTitle = "תפריט הזמנות";
  }
}());
